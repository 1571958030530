
export const defaultTheme = {
  palette: {
    primary: {
      bg: 'rgb(122, 77, 191)',
      text: 'rgb(233, 231, 239)f1f1f1',
    },
    secondary: {
      bg: 'rgb(246, 246, 248)',
      text: 'rgb(122, 115, 136)'
    }
  }
}